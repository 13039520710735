




































































































































import { Component, Vue } from 'vue-property-decorator';
import ContactPanel from '@/components/ContactPanel.vue';

@Component({
  components: {
    ContactPanel
  }
})
export default class P0197 extends Vue {}
